import { useState } from 'react';
import { InputValidation } from 'Atoms/Input/InputValidation';
import Input from 'Atoms/Input/Input';
import Button from 'Atoms/Buttons/Button';
import { accountFormValidation } from '../../../Shared/Common/AccountFormValidation';
import {
  Authenticate,
  InitiateBankIdQRCode,
  pollRegisterBankId,
} from 'Pages/AccountPage/BankId';
import BankIdModel from 'Models/Pages/AccountPage/BankIdModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import BankIdIcon from '../BankIdIcon';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { styled } from 'stitches.config';

const USER_EXISTS = 'exists_other_email';

type BankIdRegisterType = {
  inputBackground?: 'primary6' | 'primary4';
  setIsAuthenticating: (state: boolean) => void;
  setBankIdQrCode: (state: string) => void;
  setBankIdPollStatus: (state: string) => void;
  setConfirmedEmail: (state: string) => void;
  setIsOpenBankId: (state: boolean) => void;
  setIntervalId: (state: ReturnType<typeof setInterval>) => void;
  setUserAlreadyExists: (state: boolean) => void;
};

const BankIdRegisterForm = ({
  inputBackground,
  setIsAuthenticating,
  setBankIdQrCode,
  setBankIdPollStatus,
  setConfirmedEmail,
  setIsOpenBankId,
  setIntervalId,
  setUserAlreadyExists,
}: BankIdRegisterType) => {
  const { languageRoute } = useAppSettingsData();
  const { accountLabels, validationLabels } = useTranslationData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const [showValidationMsg, setShowValidationMsg] = useState<boolean>(false);

  const kexBankIdNavigate = (url: string) => {
    window.location.href = url;
  };

  const openBankIdTypeRegister = async () => {
    if (email === '' || !isEmailValid) {
      setShowValidationMsg(true);
      return;
    }
    const bankIdModel: BankIdModel = {
      forceLogout: true,
      useSameDevice: true,
      language: languageRoute,
    };

    setIsOpenBankId(true);
    setIsAuthenticating(true);

    var retval = await Authenticate(
      bankIdModel,
      () => {},
      () => {},
      kexBankIdNavigate
    );

    pollBankIdCollectRegister(retval?.[0]);
  };

  const startQRCodeTypeRegister = async () => {
    if (email === '' || !isEmailValid) {
      setShowValidationMsg(true);
      return;
    }

    setIsAuthenticating(true);
    setIsOpenBankId(false);

    const response = await InitiateBankIdQRCode(languageRoute, true);
    pollBankIdCollectRegister(response?.[0]);
  };

  const pollBankIdCollectRegister = (orderRef: string) => {
    const pollBankIdOnce = async () => {
      const res = await pollRegisterBankId(
        orderRef,
        email,
        languageRoute,
        setBankIdPollStatus,
        () => {},
        setBankIdQrCode
      );

      if (res.status !== 'pending') {
        setUserAlreadyExists(res.message === USER_EXISTS);
        clearInterval(pollingIntervalId);
      }
    };

    pollBankIdOnce();
    const pollingIntervalId = setInterval(pollBankIdOnce, 3000);
    setIntervalId(pollingIntervalId);
  };

  const setEmails = (mail: string) => {
    setConfirmedEmail(mail);
    setEmail(mail);
  };

  const onInputsInvalid = () => {
    setIsEmailValid(false);
  };

  const onInputsValid = () => {
    setIsEmailValid(true);
    setShowValidationMsg(false);
  };

  return (
    <>
      <InputValidation
        onInputsInvalid={onInputsInvalid}
        onInputsValid={onInputsValid}
      >
        <Input
          title={accountLabels.email}
          type="text"
          name="email"
          placeholder={accountLabels.email}
          onChange={setEmails}
          validation={{
            ...accountFormValidation.email,
            errorMessage: validationLabels.invalidEmail,
          }}
          autoComplete="email"
          inputBackground={inputBackground}
        />
        {showValidationMsg && (
          <ErrorText>{validationLabels.fillInEmail}</ErrorText>
        )}
        <Padding />
        {isDesktop && (
          <>
            <Button
              css={cssButton}
              type="primary"
              onClick={startQRCodeTypeRegister}
            >
              <BankIdIcon />
              {accountLabels.bankIdOnMobileDevice}
            </Button>
            <Button
              css={cssButton}
              type={'secondary'}
              onClick={openBankIdTypeRegister}
            >
              <BankIdIcon />
              {accountLabels.openBankId}
            </Button>
          </>
        )}
        {!isDesktop && (
          <>
            <Button
              css={cssButton}
              type={'primary'}
              onClick={openBankIdTypeRegister}
            >
              <BankIdIcon />
              {accountLabels.openBankId}
            </Button>
            <Button
              css={cssButton}
              type="secondary"
              onClick={startQRCodeTypeRegister}
            >
              <BankIdIcon />
              {accountLabels.bankIdOnMobileDevice}
            </Button>
          </>
        )}
      </InputValidation>
    </>
  );
};

const cssButton = { width: '100%', mt: 4, mb: 4 };

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const ErrorText = styled('div', {
  ...fontStyleCss,
  fs: 7,
  lineHeight: '$lh133',
  color: '$errorColor',
  textAlign: 'center',
  mt: 10,
});

const Padding = styled('div', {
  paddingTop: '8px',
});

export default BankIdRegisterForm;
