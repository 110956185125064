import Button from 'Atoms/Buttons/Button';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import Input from 'Atoms/Input/Input';
import { useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { accountFormValidation } from '../../../Shared/Common/AccountFormValidation';
import { styled } from 'stitches.config';
import {
  RequestNewPassword,
  RequestStatus,
} from '../../../Pages/AccountPage/Account';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import BulletPoint from '../../../Atoms/BulletPoint/BulletPoint';

type PropTypes = {
  initialEmail?: string;
};

function AccountForgottenPassword({ initialEmail }: PropTypes) {
  const { accountLabels, validationLabels } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const [email, setEmail] = useState<string>(
    !!initialEmail ? initialEmail : ''
  );
  const [state, setState] = useState<RequestStatus>('');

  const requestPassword = () => {
    RequestNewPassword(
      {
        email,
        language: languageRoute,
      },
      setState
    );
  };

  return (
    <>
      <H2>{accountLabels.forgotPassword}</H2>
      <InfoText>{accountLabels.passwordChangeBodyText}</InfoText>
      <InputValidation>
        {state === 'error' && (
          <ValidationMessage>
            {/* <ExclamationMarkIcon css={IconStyleError} /> */}
            <ErrorMessage>
              {accountLabels.requestNewPasswordFailed}
            </ErrorMessage>
          </ValidationMessage>
        )}
        <Input
          title={accountLabels.email}
          type="text"
          name="email"
          placeholder={accountLabels.email}
          onChange={setEmail}
          validation={{
            ...accountFormValidation.email,
            errorMessage: validationLabels.invalidEmail,
          }}
          defaultValue={email}
          autoComplete="email"
        />

        <Button
          type="primary"
          fullWidth
          css={{ mt: 10, mb: 6 }}
          onClick={requestPassword}
          isLoading={state === 'loading'}
        >
          {accountLabels.requestPasswordChange}
        </Button>

        {state === 'success' && (
          <BulletPoint
            backgroundColor="primary"
            text="Konfirmationsmail skickat, kolla din mail"
            css={{ mb: 8 }}
          />
        )}
      </InputValidation>
    </>
  );
}

export default AccountForgottenPassword;

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const InfoText = styled('p', {
  ...fontStyleCss,
  mb: 8.5,
});

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fs400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});
