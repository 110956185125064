import { useEffect, useState } from 'react';
import { styled } from '@stitches/react';
import { NewPassword, RequestStatus } from '../../../Pages/AccountPage/Account';
import { InputValidation } from '../../../Atoms/Input/InputValidation';
import Input from '../../../Atoms/Input/Input';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import LoadingCircle from '../../../Atoms/Loaders/LoadingCircle';
import Button from '../../../Atoms/Buttons/Button';
import { H1 } from '../../../Atoms/Typography/Headings/Heading';
import { accountFormValidation } from '../../../Shared/Common/AccountFormValidation';
import UserResetPasswordModel from '../../../Models/Pages/AccountPage/UserResetPasswordModel.interface';

export type PropType = {
  email: string;
  token: string;
  onComplete: (email: string) => void;
};

function AccountNewPassword({ email, token, onComplete }: PropType) {
  const { accountLabels, validationLabels } = useTranslationData();
  const [password, setPassword] = useState<string>('');
  const [state, setState] = useState<RequestStatus>('');
  const { languageRoute } = useAppSettingsData();

  const newPassword = () => {
    const userCredentials: UserResetPasswordModel = {
      email,
      newPassword: password,
      token,
      language: languageRoute,
    };
    NewPassword(userCredentials, setState);
  };

  useEffect(() => {
    if (state === 'success') {
      onComplete(email);
    }
  }, [onComplete, state, email]);

  return (
    <>
      <Container>
        <H1
          css={{
            fs: 16,
            mt: 1,
            marginBottom: 64,
            fontFamily: '$fontPrimary400',
          }}
        >
          {accountLabels.newPassword}
        </H1>

        {state === 'error' && (
          <ValidationMessage>
            {/* <ExclamationMarkIcon css={IconStyleError} /> */}
            <ErrorMessage>{accountLabels.resetPasswordFailed}</ErrorMessage>
          </ValidationMessage>
        )}
        <InputValidation>
          {state === 'loading' ? (
            <Text>{email}</Text>
          ) : (
            <Input
              title={accountLabels.password}
              type="password"
              name={accountLabels.newPassword}
              onChange={setPassword}
              onKeyDown={(e) => e.key === 'Enter' && newPassword()}
              newPassword={true}
              validation={{
                ...accountFormValidation.password,
                errorMessage: validationLabels.invalidPassword,
              }}
            />
          )}
          <ButtonContainer>
            <Button css={KexLinkStyle} disabled={false} onClick={newPassword}>
              {state === 'loading' ? (
                <LoadingCircle css={StyledLoadingCircle} isLoading />
              ) : (
                <>{accountLabels.savePassword}</>
              )}
            </Button>
          </ButtonContainer>
        </InputValidation>
      </Container>
    </>
  );
}

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fs400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const Text = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ButtonContainer = styled('div', {
  display: 'flex',
  mt: 8,
});

const Container = styled('div', {
  height: '80vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

const StyledLoadingCircle = {
  color: '$white',
  h: 4,
  mx: 'auto',
  my: 'auto',
};

const KexLinkStyle = {
  fontWeight: '$fw400',
  ls: '$ls125',
  backgroundColor: '$accountLinkButtonBackgroundPrimary',
  color: '$textSecondary',
  br: 'none',
  width: '100%',
  minH: 11,
  mt: 7,
};

export default AccountNewPassword;
