import { InputValidation } from 'Atoms/Input/InputValidation';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import Input from 'Atoms/Input/Input';
import { styled } from 'stitches.config';
import { useEffect, useState } from 'react';
import TabButton from 'Atoms/Buttons/TabButton';
import TabButtonGroup from 'Molecules/ButtonGroups/TabButtonGroup';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import Button from 'Atoms/Buttons/Button';
import { InfoIcon } from 'Atoms/Icons';
import LoadingCircle from 'Atoms/Loaders/LoadingCircle';
import {
  ModalName,
  ModalState,
  TabType,
} from 'Organisms/AccountModal/AccountModal';
import {
  RegisterNewEmailUser,
  RequestStatus,
  SendRegistrationEmail,
} from 'Pages/AccountPage/Account';
import UserCredentialsModels from 'Models/Pages/AccountPage/UserCredentialsModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import BankIdRegisterForm from './BankIdRegisterForm';
import { accountFormValidation } from '../../../Shared/Common/AccountFormValidation';
import SwitchToLoginView from './SwitchToLoginView';
import BankIdAuthentication from '../Containers/BankIdAuthentication/BankIdAuthentication';

type PropTypes = {
  switchModal: (state: ModalState) => void;
  currentTab: TabType;
  setCurrentTab: (type: TabType) => void;
};

function AccountRegister({
  switchModal,
  currentTab,
  setCurrentTab,
}: PropTypes) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthFailed, setIsAuthFailed] = useState<boolean>(false);
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false);

  const [bankIdQrCode, setBankIdQrCode] = useState<string>('');
  const [bankIdPollStatus, setBankIdPollStatus] = useState<string>('');
  const [isOpenBankId, setIsOpenBankId] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>(0);

  const [registerStatus, setRegisterStatus] = useState<RequestStatus>();
  const [emailValidationMsg, setEmailValidationMsg] = useState<string>('');
  const [resendStatus, setResendStatus] = useState<
    RequestStatus | 'alreadyRegistered'
  >('');
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const { languageRoute } = useAppSettingsData();
  const { accountLabels, validationLabels, errorLabels } = useTranslationData();

  useEffect(() => {
    if (registerStatus === 'success') {
      setIsAccountCreated(true);
    }
  }, [registerStatus]);

  useEffect(() => {
    switch (bankIdPollStatus.toLocaleLowerCase()) {
      case 'failed':
        setIsAuthFailed(true);
        setIsAuthenticating(false);
        break;
      case 'pending':
        setIsAuthenticating(true);
        break;
      case 'complete':
        setIsAuthFailed(false);
        setIsAuthenticating(false);
        setIsAccountCreated(true);
        break;
      default:
    }
  }, [bankIdPollStatus]);

  const onInputsValid = () => {
    setBtnEnabled(true);
  };

  const onInputsInvalid = () => {
    setBtnEnabled(false);
  };

  const BankidRegisterForm = () => {
    return (
      <>
        <BulletsContainer>
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletFirst}
            backgroundColor="secondaryDark"
          />
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletSecond}
            backgroundColor="secondaryDark"
          />
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletThird}
            backgroundColor="secondaryDark"
          />
        </BulletsContainer>

        <BankIdRegisterForm
          setIsAuthenticating={setIsAuthenticating}
          setBankIdQrCode={setBankIdQrCode}
          setBankIdPollStatus={setBankIdPollStatus}
          setConfirmedEmail={setEmail}
          setIsOpenBankId={setIsOpenBankId}
          setIntervalId={setIntervalId}
          setUserAlreadyExists={setUserAlreadyExists}
        />
        <SwitchToLoginView
          switchView={() => switchModal({ name: ModalName.LOGIN })}
        />
      </>
    );
  };

  const onSubmitHandlerEmail = async () => {
    if (email === '' || password === '') return;

    const userCredentials: UserCredentialsModels = {
      email: email,
      password: password,
      language: languageRoute,
    };

    await RegisterNewEmailUser(
      userCredentials,
      setRegisterStatus,
      setEmailValidationMsg
    );
  };

  const EmailRegisterForm = () => {
    return (
      <>
        <BulletsContainer>
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletFirst}
            backgroundColor="secondaryDark"
          />
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletSecond}
            backgroundColor="secondaryDark"
          />
          <BulletPoint
            css={cssBulletPoint}
            text={accountLabels.accountBulletThird}
            backgroundColor="secondaryDark"
          />
        </BulletsContainer>
        <InputValidation
          onInputsValid={onInputsValid}
          onInputsInvalid={onInputsInvalid}
        >
          <Input
            title={accountLabels.email}
            type="text"
            name="email"
            placeholder={accountLabels.email}
            onChange={setEmail}
            validation={{
              ...accountFormValidation.email,
              errorMessage: validationLabels.invalidEmail,
            }}
          />

          <Input
            title={accountLabels.password}
            type="password"
            name="password"
            placeholder={accountLabels.password}
            onChange={setPassword}
            validation={{
              ...accountFormValidation.password,
              errorMessage: validationLabels.invalidPassword,
            }}
          />
          {registerStatus === 'error' && (
            <ErrorText>{emailValidationMsg}</ErrorText>
          )}
          <Button
            fullWidth
            css={{ mt: 8, mb: 6 }}
            onClick={onSubmitHandlerEmail}
            disabled={!btnEnabled}
            isLoading={registerStatus === 'loading'}
          >
            {accountLabels.createAccount}
          </Button>
        </InputValidation>
        <SwitchToLoginView
          switchView={() => switchModal({ name: ModalName.LOGIN })}
        />
      </>
    );
  };

  const AccountCreated = () => {
    const resend = async () => {
      if (email === '') {
        console.error('No email');
        return;
      }

      await SendRegistrationEmail(
        {
          email: email,
          language: languageRoute,
        },
        setResendStatus
      );
    };

    if (resendStatus === 'alreadyRegistered') {
      return (
        <AccountCreatedContainer>
          <p>{accountLabels.emailAlreadyConfirmed}</p>
          <SwitchToLoginView
            switchView={() => switchModal({ name: ModalName.LOGIN })}
          />
        </AccountCreatedContainer>
      );
    }

    return (
      <AccountCreatedContainer>
        <BulletPoint
          css={{
            my: 2,
            mb: 11,
            flexGrow: 1,
            flexShrink: 0,
          }}
          text={accountLabels.confirmYourAccount?.replace('{0}', email)}
          backgroundColor={'primary'}
        />

        <TextWrapper>
          {resendStatus === 'loading' && (
            <LoadingCircle isLoading={true}></LoadingCircle>
          )}
          {resendStatus !== 'loading' && (
            <>
              <StyledText> {accountLabels.didYouGetMail}</StyledText>
              <StyledLink onClick={resend}>
                {accountLabels.sendAgain}
              </StyledLink>
            </>
          )}
        </TextWrapper>
      </AccountCreatedContainer>
    );
  };

  const BankIdAuthAborted = () => {
    return (
      <ErrorText css={{ textAlign: 'left' }}>
        <ErrorTextWrapper>
          <InfoIcon space={true} size={'l'} color={'error'} />
          {userAlreadyExists
            ? errorLabels.errorBankIdExistingUser
            : errorLabels.bankIdAuthInterrupted}
        </ErrorTextWrapper>
      </ErrorText>
    );
  };

  const createAccountOrAccountCreated = isAccountCreated
    ? accountLabels.accountCreated
    : accountLabels.createAccount;

  return (
    <>
      <H2 css={StyledH2}>{createAccountOrAccountCreated}</H2>
      {isAccountCreated && AccountCreated()}
      {isAuthenticating && (
        <BankIdAuthentication
          setIsAuthenticating={setIsAuthenticating}
          setIsAuthFailed={setIsAuthFailed}
          isOpenBankId={isOpenBankId}
          bankIdQrCode={bankIdQrCode}
          isAuthenticating={isAuthenticating}
          intervalId={intervalId}
          setIntervalId={setIntervalId}
        />
      )}
      {isAuthFailed && !isAuthenticating && BankIdAuthAborted()}
      {!isAuthenticating && !isAccountCreated && (
        <>
          <TabButtonGroup>
            <TabButton
              active={currentTab === 'BANKID'}
              onClick={() => {
                setCurrentTab('BANKID');
              }}
            >
              {accountLabels.bankId}
            </TabButton>
            <TabButton
              active={currentTab === 'EPOSTADRESS'}
              onClick={() => {
                setCurrentTab('EPOSTADRESS');
                setIsAuthFailed(false);
              }}
            >
              {accountLabels.email}
            </TabButton>
          </TabButtonGroup>
          <StyledTabContent>
            {currentTab === 'BANKID' && BankidRegisterForm()}
            {currentTab === 'EPOSTADRESS' && EmailRegisterForm()}
          </StyledTabContent>
        </>
      )}
    </>
  );
}

export default AccountRegister;

const cssBulletPoint = {};

const AccountCreatedContainer = styled('div', {
  mt: 15,
});

const BulletsContainer = styled('div', {
  pb: 11,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  fs: 6,
  '@mediaMinLarge': {
    fs: 8,
  },
});

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const StyledH2 = {
  mt: 4,
  mb: 8,
  '@mediaMinLarge': {
    mt: 10,
    mb: 12,
  },
};

const StyledTabContent = styled('div', {
  color: '$secondary2',
  pt: 12,
  pb: 16,
});

const TextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

const ErrorText = styled('div', {
  ...fontStyleCss,
  fs: 7,
  lineHeight: '$lh133',
  color: '$errorColor',
  textAlign: 'center',
  mt: 10,
});

const ErrorTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '16px',
  gap: '0',
});

const StyledText = styled('span', {
  ...fontStyleCss,
});

const StyledLink = styled('span', {
  ...fontStyleCss,
  paddingLeft: '4px',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
});
