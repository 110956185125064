import BankIdModel from 'Models/Pages/AccountPage/BankIdModel.interface';
import BankIdCollectModel from 'Models/Pages/AccountPage/BankIdCollectModel.interface';
import { updateHeader } from 'Shared/Hooks/useQueryHeader';
import { updateUserState } from 'Shared/Hooks/useQueryUserState';
import { canUseDOM } from 'Shared/Common/Helpers';
import CollectTypeEnum from 'Models/Pages/AccountPage/CollectTypeEnum.interface';

export async function Authenticate(
  bankIdModel: BankIdModel,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  kexBankIdNavigate: (value: string) => void
) {
  setError(false);
  setValidationMessage('');

  const res = await fetch(`/bankid/AuthenticateAsync`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bankIdModel),
  });
  try {
    const data = await res.json();
    if (data.statusCode === 200) {
      updateHeader(bankIdModel.language);
      updateUserState();
      kexBankIdNavigate(data.bankIdUrl);
      return [data.orderRef, data.qrStartToken, data.qrStartSecret];
    } else {
      setError(true);
      setValidationMessage(data.message);
    }
  } catch (e) {
    console.error(e);
  }
}

export async function InitiateBankIdQRCode(
  languageRoute: string,
  forceLogout: boolean
) {
  const res = await fetch(`/bankid/AuthenticateAsync`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      forceLogout: forceLogout,
      language: languageRoute,
      personalNumber: '',
      useSameDevice: false,
    }),
  });

  try {
    const data = await res.json();
    if (data.statusCode === 200) {
      updateHeader(languageRoute);
      updateUserState();
      return [data.orderRef, data.qrStartToken, data.qrStartSecret];
    }
  } catch (e) {
    console.error(e);
  }
}

export async function Collect(bankIdCollectModel: BankIdCollectModel) {
  if (bankIdCollectModel.orderRef) {
    const res = await fetch(`/bankid/CollectAsync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bankIdCollectModel),
    });
    try {
      const data = await res.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}

export async function pollRegisterBankId(
  orderRef: string,
  email: string,
  languageRoute: string,
  setBankIdPollStatus: (text: string) => void,
  setBankIdPollHint: (text: string) => void,
  setBankIdQrCode: (text: string) => void
) {
  return pollBankId(
    orderRef,
    CollectTypeEnum.Register,
    email,
    languageRoute,
    setBankIdPollStatus,
    setBankIdPollHint,
    setBankIdQrCode
  );
}

export async function pollLoginBankId(
  orderRef: string,
  languageRoute: string,
  setBankIdPollStatus: (text: string) => void,
  setBankIdQrCode: (text: string) => void
) {
  return pollBankId(
    orderRef,
    CollectTypeEnum.Login,
    '',
    languageRoute,
    setBankIdPollStatus,
    () => {},
    setBankIdQrCode
  );
}

export async function pollIdentifyBankId(
  orderRef: string,
  languageRoute: string,
  setBankIdPollStatus: (text: string) => void,
  setBankIdQrCode: (text: string) => void
) {
  return pollBankId(
    orderRef,
    CollectTypeEnum.Identify,
    '',
    languageRoute,
    setBankIdPollStatus,
    () => {},
    setBankIdQrCode
  );
}

export async function pollBankId(
  orderRef: string,
  pollType: CollectTypeEnum,
  email: string,
  languageRoute: string,
  setBankIdPollStatus: (text: string) => void,
  setBankIdPollHint: (text: string) => void,
  setBankIdQrCode: (text: string) => void
) {
  const collectModel: BankIdCollectModel = {
    collectType: pollType,
    email: email,
    orderRef: orderRef,
    language: languageRoute,
  };

  var data = await Collect(collectModel);
  if (data) {
    setBankIdPollStatus(data.status);
    setBankIdPollHint(data.hintCode);
    setBankIdQrCode(data.qrCodeString);

    if (data.status !== 'pending') {
      setBankIdQrCode('');
    }

    if (data.status === 'complete') {
      updateUserState();
      updateHeader(languageRoute);
      // Purpose to get updated state so we can populate existing information - edge case checkout page stores delivery address etc. on currentPage
      canUseDOM() && window.location.reload();
    }

    return {
      status: data.status as string,
      hintCode: data.hintCode as string,
      qrCode: data.qrCodeString as string,
      message: data.message as string,
    };
  } else {
    return {
      status: 'error',
      hintCode: 'failed to collect',
      qrCode: '',
    };
  }
}
