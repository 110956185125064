import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';

type PropTypes = {
  switchView: () => void;
};

const AlreadyHasAccount = ({ switchView }: PropTypes) => {
  const { accountLabels } = useTranslationData();
  return (
    <TextWrapper>
      <StyledText> {accountLabels.alreadyHaveAccount}</StyledText>
      <StyledLink onClick={switchView}>{accountLabels.signIn}</StyledLink>
    </TextWrapper>
  );
};

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const TextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  mt: 6,
});

const StyledText = styled('span', {
  ...fontStyleCss,
});

const StyledLink = styled('span', {
  ...fontStyleCss,
  paddingLeft: '4px',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
});

export default AlreadyHasAccount;
