import { H2 } from 'Atoms/Typography/Headings/Heading';
import { useEffect, useState } from 'react';
import Button from 'Atoms/Buttons/Button';
import {
  ModalName,
  ModalState,
  TabType,
} from 'Organisms/AccountModal/AccountModal';
import TabButton from 'Atoms/Buttons/TabButton';
import TabButtonGroup from 'Molecules/ButtonGroups/TabButtonGroup';
import { InputValidation } from 'Atoms/Input/InputValidation';
import Input from 'Atoms/Input/Input';
import UserCredentialsModel from 'Models/Pages/AccountPage/UserCredentialsModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { SignIn } from 'Pages/AccountPage/Account';
import { canUseDOM, isEmpty } from 'Shared/Common/Helpers';
import BankIdLoginForm from './BankIdLoginForm';
import SwitchToRegisterView from './SwitchToRegisterView';
import { accountFormValidation } from '../../../Shared/Common/AccountFormValidation';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import usePost from '../../../Shared/Hooks/usePost';
import UserTokenCredentialsModels from '../../../Models/Pages/AccountPage/UserTokenCredentialsModel.interface';
import { InfoIcon } from 'Atoms/Icons';
import BankIdAuthentication from '../Containers/BankIdAuthentication/BankIdAuthentication';

type PropTypes = {
  switchModal: (state: ModalState) => void;
  initialEmail?: string;
  confirmEmail?: UserTokenCredentialsModels;
  closeModal: () => void;
  currentTab: TabType;
  setCurrentTab: (type: TabType) => void;
  clearTokens: () => void;
};

const API_URL = '/Account/ConfirmEmail';

function AccountLogin({
  switchModal,
  closeModal,
  initialEmail,
  confirmEmail,
  clearTokens,
  currentTab,
  setCurrentTab,
}: PropTypes) {
  const { accountLabels, validationLabels, errorLabels } = useTranslationData();

  const [email, setEmail] = useState<string>(initialEmail ?? '');
  const [password, setPassword] = useState<string>('');

  const confirmationInfo = usePost<object>(
    API_URL,
    confirmEmail,
    !!confirmEmail
  );

  const [isLoadingEmailLoginBtn, setIsLoadingEmailLoginBtn] =
    useState<boolean>(false);
  const [emailValidationMsg, setEmailValidationMsg] = useState<string>('');
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthFailed, setIsAuthFailed] = useState<boolean>(false);
  const [bankIdQrCode, setBankIdQrCode] = useState<string>('');
  const [bankIdPollStatus, setBankIdPollStatus] = useState<string>('');
  const [isOpenBankId, setIsOpenBankId] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>(0);

  useEffect(() => {
    if (confirmationInfo.data || confirmationInfo.error) {
      clearTokens();
    }
  }, [confirmationInfo, clearTokens]);

  const { languageRoute } = useAppSettingsData();

  useEffect(() => {
    switch (bankIdPollStatus.toLocaleLowerCase()) {
      case 'failed':
        setIsAuthFailed(true);
        setIsAuthenticating(false);
        break;
      case 'pending':
        setIsAuthenticating(true);
        break;
      case 'complete':
        setIsAuthFailed(false);
        setIsAuthenticating(false);
        closeModal();
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankIdPollStatus]);

  const resetFormCredentials = () => {
    setEmail('');
    setPassword('');
  };

  const BankidLoginForm = () => {
    return (
      <>
        <BankIdLoginForm
          setIsAuthenticating={setIsAuthenticating}
          setBankIdQrCode={setBankIdQrCode}
          setBankIdPollStatus={setBankIdPollStatus}
          setIsOpenBankId={setIsOpenBankId}
          setIntervalId={setIntervalId}
        />
        <SwitchToRegisterView
          switchView={() => switchModal({ name: ModalName.REGISTER })}
        />
      </>
    );
  };

  const onClickEmailLogin = async () => {
    if (email === '' || password === '') return;

    const userCredentials: UserCredentialsModel = {
      email: email,
      password: password,
      language: languageRoute,
    };

    const status = await SignIn(
      userCredentials,
      setEmailValidationMsg,
      setIsLoadingEmailLoginBtn
    );

    if (status === 200 && canUseDOM()) {
      closeModal();
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
  };

  const EmailLoginForm = () => {
    return (
      <>
        {confirmationInfo.data && (
          <ConfirmationText>{accountLabels.emailConfirmed}</ConfirmationText>
        )}
        {!!confirmationInfo.error && (
          <ErrorMessage css={{ mb: 2 }}>
            {accountLabels.emailConfirmationFailed}
          </ErrorMessage>
        )}

        <InputValidation>
          <Input
            title={accountLabels.email}
            type="text"
            name="email"
            placeholder={accountLabels.email}
            onChange={setEmail}
            onKeyDown={(e) => e.key === 'Enter' && onClickEmailLogin()}
            validation={{
              ...accountFormValidation.email,
              errorMessage: validationLabels.invalidEmail,
            }}
            autoComplete="email"
            defaultValue={confirmEmail?.email}
          />

          <Input
            title={accountLabels.password}
            type="password"
            name="password"
            placeholder={accountLabels.password}
            onChange={setPassword}
            onKeyDown={(e) => e.key === 'Enter' && onClickEmailLogin()}
            validation={{
              ...accountFormValidation.password,
              errorMessage: validationLabels.invalidPassword,
            }}
          />
          <TextWrapper>
            <StyledLink
              css={{ mt: 3, mb: 2 }}
              onClick={() => {
                switchModal({
                  name: ModalName.FORGOTTEN,
                  email,
                });
              }}
            >
              {accountLabels.forgotPassword}
            </StyledLink>
          </TextWrapper>
          {!isEmpty(emailValidationMsg) && (
            <ErrorText>{emailValidationMsg}</ErrorText>
          )}
          <Button
            type="primary"
            fullWidth
            css={{ mt: 10, mb: 6 }}
            onClick={onClickEmailLogin}
            isLoading={isLoadingEmailLoginBtn}
          >
            {accountLabels.signIn}
          </Button>
        </InputValidation>
        <SwitchToRegisterView
          switchView={() => switchModal({ name: ModalName.REGISTER })}
        />
      </>
    );
  };

  const BankIdAuthAborted = () => {
    return (
      <ErrorText css={{ textAlign: 'left' }}>
        <ErrorTextWrapper>
          <InfoIcon space={true} size={'l'} color={'error'} />
          {errorLabels.bankIdAuthInterrupted}
        </ErrorTextWrapper>
      </ErrorText>
    );
  };

  return (
    <>
      <H2 css={StyledH2}>{accountLabels.signIn}</H2>
      {isAuthenticating && (
        <BankIdAuthentication
          setIsAuthenticating={setIsAuthenticating}
          setIsAuthFailed={setIsAuthFailed}
          isOpenBankId={isOpenBankId}
          bankIdQrCode={bankIdQrCode}
          isAuthenticating={isAuthenticating}
          intervalId={intervalId}
          setIntervalId={setIntervalId}
        />
      )}
      {isAuthFailed && !isAuthenticating && BankIdAuthAborted()}
      {!isAuthenticating && (
        <>
          <TabButtonGroup>
            <TabButton
              active={currentTab === 'BANKID'}
              onClick={() => {
                resetFormCredentials();
                setCurrentTab('BANKID');
              }}
            >
              {accountLabels.bankId}
            </TabButton>
            <TabButton
              active={currentTab === 'EPOSTADRESS'}
              onClick={() => {
                resetFormCredentials();
                setCurrentTab('EPOSTADRESS');
                setIsAuthFailed(false);
              }}
            >
              {accountLabels.email}
            </TabButton>
          </TabButtonGroup>
          <StyledTabContent>
            {currentTab === 'BANKID' && BankidLoginForm()}
            {currentTab === 'EPOSTADRESS' && EmailLoginForm()}
          </StyledTabContent>
        </>
      )}
    </>
  );
}

export default AccountLogin;

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const StyledH2 = {
  mt: 4,
  mb: 8,
  '@mediaMinLarge': {
    mt: 10,
    mb: 12,
  },
};

const StyledTabContent = styled('div', {
  color: '$secondary2',
  pt: 12,
  pb: 16,
});

const TextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

const ErrorText = styled('div', {
  ...fontStyleCss,
  fs: 7,
  lineHeight: '$lh133',
  color: '$errorColor',
  textAlign: 'center',
  mt: 10,
});

const StyledLink = styled('span', {
  ...fontStyleCss,
  paddingLeft: '4px',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ConfirmationText = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  mb: 2,
  color: '$JE68GreenPrimary',
});

const ErrorTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '16px',
  gap: '0',
});
