import LoadingCircle from 'Atoms/Loaders/LoadingCircle';
import QRCode from 'react-qr-code';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';

type PropTypes = {
  setIsAuthenticating: (state: boolean) => void;
  setIsAuthFailed: (state: boolean) => void;
  isOpenBankId: boolean;
  bankIdQrCode: string;
  isAuthenticating: boolean;
  intervalId: number;
  setIntervalId: (state: number) => void;
};

const BankIdAuthentication = ({
  setIsAuthenticating,
  setIsAuthFailed,
  isOpenBankId,
  bankIdQrCode,
  isAuthenticating,
  intervalId,
  setIntervalId,
}: PropTypes) => {
  const { accountLabels } = useTranslationData();

  const CancelSignIn = () => {
    const stopPollingInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(0);
      }
    };

    return (
      <TextWrapper css={{ justifyContent: 'center' }}>
        <StyledLink
          onClick={() => {
            setIsAuthenticating(false);
            setIsAuthFailed(false);
            stopPollingInterval();
          }}
        >
          {accountLabels.cancelSignIn}
        </StyledLink>
      </TextWrapper>
    );
  };
  return (
    <>
      {!isOpenBankId && (
        <>
          <ol>
            <StyledListItem>1. {accountLabels.bankIdLoginStep1}</StyledListItem>
            <StyledListItem>2. {accountLabels.bankIdLoginStep2}</StyledListItem>
            <StyledListItem>3. {accountLabels.bankIdLoginStep3}</StyledListItem>
          </ol>
          {bankIdQrCode && (
            <>
              <QRCodeContainer>
                <QRCode value={bankIdQrCode} size={192} />
              </QRCodeContainer>
              <CancelSignIn />
            </>
          )}
        </>
      )}
      {isOpenBankId && (
        <>
          <StyledLoadingIcon>
            <LoadingCircle size="l" isLoading={isAuthenticating} />
          </StyledLoadingIcon>
          <WrapperStyledText>
            {accountLabels.searchingForBankId}
          </WrapperStyledText>
          <CancelSignIn />
        </>
      )}
    </>
  );
};

export default BankIdAuthentication;

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const TextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledLink = styled('span', {
  ...fontStyleCss,
  paddingLeft: '4px',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
});

const StyledLoadingIcon = styled('div', {
  marginTop: '25vh',
  marginLeft: 'auto',
  marginRight: 'auto',
  w: 16,
  pb: 8,
});

const WrapperStyledText = styled('div', {
  ...fontStyleCss,
  textAlign: 'center',
  marginBottom: '32px',
});

const QRCodeContainer = styled('div', {
  backgroundColor: 'white',
  padding: '16px',
  maxWidth: '224px',
  marginTop: '40px',
  marginBottom: '48px',
  mx: 'auto',
});

const StyledListItem = styled('li', {
  textAlign: 'center',
  color: '$secondary2',
});
