import { useEffect, useReducer, useRef } from 'react';

interface State<T> {
  data?: T;
  error?: Error;
}

type Cache<T> = { [url: string]: T };

interface OptionsType {
  method: string;
  headers?: HeadersInit | undefined;
  body: string | FormData;
}

type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error };

function usePost<T = unknown>(
  url?: string,
  body: any = {},
  triggerDirectly: boolean = true,
  stringifyBody: boolean = true,
  sendHeaders: boolean = true
): State<T> {
  const cache = useRef<Cache<T>>({});

  const options: OptionsType = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: stringifyBody ? JSON.stringify(body) : body,
  };

  if (!sendHeaders) {
    delete options.headers;
  }

  // Used to prevent state update if the component is unmounted

  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState };
      case 'fetched':
        return { ...initialState, data: action.payload };
      case 'error':
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given or if call isn't requested
    if (!url) return;
    if (!triggerDirectly) return;

    const fetchData = async () => {
      dispatch({ type: 'loading' });

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        dispatch({
          type: 'fetched',
          payload: cache.current[url],
        });
        return;
      }

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        // server needs to send json otherwise response.json() will crash
        const data = (await response.json()) as T;
        cache.current[url] = data;

        if (cancelRequest.current) return;

        dispatch({ type: 'fetched', payload: data });
      } catch (error) {
        if (cancelRequest.current) return;

        dispatch({
          type: 'error',
          payload: error as Error,
        });
      }
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, triggerDirectly]);

  return state;
}

export default usePost;
