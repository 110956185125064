import UserCredentialsModel from 'Models/Pages/AccountPage/UserCredentialsModel.interface';
import UserEmailModel from 'Models/Pages/AccountPage/UserEmailModel.interface';
import UpdateUserPersonalInformationRequest from 'Models/User/UpdateUserPersonalInformationRequest.interface';
import UpdateUserProfileRequest from 'Models/User/UpdateUserProfileRequest.interface';
import { updateHeader } from 'Shared/Hooks/useQueryHeader';
import { updateUserState } from 'Shared/Hooks/useQueryUserState';
import UserResetPasswordModel from '../../Models/Pages/AccountPage/UserResetPasswordModel.interface';

export async function SignIn(
  userCredentials: UserCredentialsModel,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  setValidationMessage('');

  let statusCode;

  await fetch(`/Account/SignIn`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      statusCode = data.statusCode;
      if (data.statusCode === 200) {
        updateHeader(userCredentials.language);
        updateUserState();
      } else {
        setValidationMessage(data.message);
      }
    })
    .catch((err) => {
      setValidationMessage(err.message);
    });

  setIsLoading(false);
  return statusCode;
}

export type RequestStatus = 'error' | 'loading' | 'success' | '';

export async function RequestNewPassword(
  userCredentials: UserEmailModel,
  onState: (type: RequestStatus) => void
) {
  onState('loading');

  const res = await fetch('Account/RequestNewPassword', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials),
  });

  if (res.ok) {
    updateHeader(userCredentials.language);
    onState('success');
  } else {
    console.error(res);
    onState('error');
  }
}

export async function NewPassword(
  userCredentials: UserResetPasswordModel,
  onState: (state: RequestStatus) => void
) {
  onState('loading');
  const res = await fetch('Account/NewPassword', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials),
  });

  try {
    const data = await res.json();
    if (data.statusCode === 200) {
      updateHeader(userCredentials.language);
      updateUserState();
      onState('success');
    } else {
      onState('error');
    }
  } catch (e) {
    console.error(e);
    onState('error');
  }
}

export async function SignOut(languageRoute: string) {
  const res = await fetch(`/Account/SignOut?language=${languageRoute}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateUserState();
    window.location.href = data.redirectUrl;
  }
}

export async function SendRegistrationEmail(
  model: UserEmailModel,
  onState: (state: RequestStatus | 'alreadyRegistered') => void
) {
  onState('loading');

  await fetch('/Account/SendRegistrationMail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(model),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      if (data.statusCode === 200) {
        onState('success');
      } else if (data.statusCode === 422) {
        onState('alreadyRegistered');
      } else {
        onState('error');
        console.error(data);
      }
    })
    .catch((err) => {
      onState('error');
      console.error(err.message);
    });
}

export async function RegisterNewEmailUser(
  userCredentials: UserCredentialsModel,
  onState: (state: RequestStatus) => void,
  setValidationMessage: (value: string) => void
) {
  onState('loading');
  setValidationMessage('');

  await fetch('/Account/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      if (data.statusCode === 200) {
        onState('success');
      } else {
        onState('error');
        setValidationMessage(data.message);
      }
    })
    .catch((err) => {
      onState('error');
      setValidationMessage(err.message);
    });
}

export async function UpdatePersonalInformation(
  request: UpdateUserPersonalInformationRequest,
  languageRoute: string,
  onState: (state: RequestStatus) => void,
  setValidationMessage: (value: string) => void
) {
  onState('loading');
  setValidationMessage('');

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  await fetch(`/Account/UpdatePersonalInformation?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      if (data.statusCode === 200) {
        onState('success');
      } else {
        onState('error');
        setValidationMessage(data.message);
      }
    })
    .catch((err) => {
      onState('error');
      setValidationMessage(err.message);
    });
}

export async function UpdateProfile(
  request: UpdateUserProfileRequest,
  languageRoute: string,
  onState: (state: RequestStatus) => void,
  setValidationMessage: (value: string) => void
) {
  onState('loading');
  setValidationMessage('');

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  await fetch(`/Account/UpdateProfile?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error('Could not fetch data from resource');
      }
      return res.json();
    })
    .then((data) => {
      if (data.statusCode === 200) {
        onState('success');
      } else {
        onState('error');
        setValidationMessage(data.message);
      }
    })
    .catch((err) => {
      onState('error');
      setValidationMessage(err.message);
    });
}
