import Button from 'Atoms/Buttons/Button';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import BankIdIcon from '../BankIdIcon';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import BankIdModel from 'Models/Pages/AccountPage/BankIdModel.interface';
import {
  Authenticate,
  pollLoginBankId,
  InitiateBankIdQRCode,
} from 'Pages/AccountPage/BankId';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

type BankIdLoginType = {
  inputBackground?: 'primary4' | 'primary6';
  setIsAuthenticating: (state: boolean) => void;
  setBankIdQrCode: (state: string) => void;
  setBankIdPollStatus: (state: string) => void;
  setIsOpenBankId: (state: boolean) => void;
  setIntervalId: (state: ReturnType<typeof setInterval>) => void;
};

const BankIdLoginForm = ({
  setIsAuthenticating,
  setBankIdQrCode,
  setBankIdPollStatus,
  setIsOpenBankId,
  setIntervalId,
}: BankIdLoginType) => {
  const { accountLabels } = useTranslationData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const { languageRoute } = useAppSettingsData();

  const kexBankIdNavigate = (url: string) => {
    window.location.href = url;
  };

  const openBankIdTypeLogin = async () => {
    const bankIdModel: BankIdModel = {
      forceLogout: true,
      useSameDevice: true,
      language: languageRoute,
    };

    setIsOpenBankId(true);
    setIsAuthenticating(true);

    var retval = await Authenticate(
      bankIdModel,
      () => {},
      () => {},
      kexBankIdNavigate
    );

    pollBankIdCollectLogin(retval?.[0]);
  };

  const startQRCodeTypeRegister = async () => {
    setIsAuthenticating(true);
    setIsOpenBankId(false);

    const response = await InitiateBankIdQRCode(languageRoute, true);
    pollBankIdCollectLogin(response?.[0]);
  };

  const pollBankIdCollectLogin = (orderRef: string) => {
    const pollBankIdOnce = async () => {
      const res = await pollLoginBankId(
        orderRef,
        languageRoute,
        setBankIdPollStatus,
        setBankIdQrCode
      );

      if (res.status !== 'pending') {
        clearInterval(pollingIntervalId);
      }
    };

    pollBankIdOnce();
    const pollingIntervalId = setInterval(pollBankIdOnce, 3000);
    setIntervalId(pollingIntervalId);
  };

  return (
    <>
      {isDesktop && (
        <>
          <Button
            css={cssButton}
            type="primary"
            onClick={startQRCodeTypeRegister}
          >
            <BankIdIcon />
            {accountLabels.bankIdOnMobileDevice}
          </Button>
          <Button
            css={cssButton}
            type={'secondary'}
            onClick={openBankIdTypeLogin}
          >
            <BankIdIcon />
            {accountLabels.openBankId}
          </Button>
        </>
      )}
      {!isDesktop && (
        <>
          <Button
            css={cssButton}
            type={'primary'}
            onClick={openBankIdTypeLogin}
          >
            <BankIdIcon />
            {accountLabels.openBankId}
          </Button>
          <Button
            css={cssButton}
            type="secondary"
            onClick={startQRCodeTypeRegister}
          >
            <BankIdIcon />
            {accountLabels.bankIdOnMobileDevice}
          </Button>
        </>
      )}
    </>
  );
};

const cssButton = { width: '100%', mt: 4, mb: 4 };

export default BankIdLoginForm;
