import { styled } from 'stitches.config';

type Proptype = {
  children?: React.ReactNode;
};

function TabButtonGroup({ children }: Proptype) {
  return <StyledTabButtonGroup>{children}</StyledTabButtonGroup>;
}

const StyledTabButtonGroup = styled('div', {
  ls: '$ls05',
  lineHeight: '$lh175',
  cursor: 'pointer',
  overflow: 'auto',
  '@mediaMinLarge': {
    overflow: 'unset',
  },
  display: 'inline-flex',
  gap: '4px',
  w: '100%',
  '@mediaMaxLarge': {
    w: '100vw',
    px: 4,
    left: '50%',
    right: '50%',
    maxWidth: '100vw',
    position: 'relative',
    marginLeft: `-50vw`,
    marginRight: '-50vw',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export default TabButtonGroup;
