import Modal from 'Organisms/Modal/Modal';
import { useCallback, useState } from 'react';

import AccountRegister from './AccountRegister/AccountRegister';
import AccountLogin from './AccountLogin/AccountLogin';
import AccountForgottenPassword from './AccountForgottenPassword/AccountForgottenPassword';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import AccountNewPassword from './AccountNewPassword/AccountNewPassword';

export enum ModalName {
  REGISTER = 'register',
  LOGIN = 'login',
  FORGOTTEN = 'forgotten',
  RESET = 'reset',
  CONFIRM = 'confirm',
}

export type ModalNameWithToken = ModalName.RESET | ModalName.CONFIRM;
export type ModalNameWithoutToken =
  | ModalName.REGISTER
  | ModalName.LOGIN
  | ModalName.FORGOTTEN;

export type ModalState =
  | {
      name: ModalNameWithToken;
      email: string;
      token: string;
    }
  | {
      name: ModalNameWithoutToken;
      email?: string;
    };

export type PropType = {
  showModal: boolean;
  toggle: () => void;
  clearTokens: () => void;
  initialModalState: ModalState;
};

export type TabType = 'BANKID' | 'EPOSTADRESS';

function AccountModal({
  initialModalState,
  showModal,
  toggle,
  clearTokens,
}: PropType) {
  const [modal, setModal] = useState<ModalState>(initialModalState);
  const { languageRoute } = useAppSettingsData();

  const [currentTab, setCurrentTab] = useState<TabType>(
    initialModalState.name === ModalName.CONFIRM ? 'EPOSTADRESS' : 'BANKID'
  );

  const clearTokensAndSwitchToLogin = useCallback(
    (email?: string) => {
      setModal({
        name: ModalName.LOGIN,
        email,
      });
      setCurrentTab('EPOSTADRESS');
      clearTokens();
    },
    [setModal, clearTokens, setCurrentTab]
  );

  const resetModalAfterToggle = () => {
    toggle();
    setTimeout(() => {
      setModal(initialModalState);
    }, 400);
  };

  return (
    <Modal showModal={showModal} toggle={resetModalAfterToggle} overlay={true}>
      {modal.name === ModalName.REGISTER && (
        <AccountRegister
          switchModal={setModal}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {modal.name === ModalName.LOGIN && (
        <AccountLogin
          switchModal={setModal}
          closeModal={toggle}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          clearTokens={clearTokens}
        />
      )}
      {modal.name === ModalName.CONFIRM && (
        <AccountLogin
          switchModal={setModal}
          initialEmail={modal.email}
          confirmEmail={{
            email: modal.email,
            token: modal.token,
            language: languageRoute,
          }}
          closeModal={toggle}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          clearTokens={clearTokens}
        />
      )}
      {modal.name === ModalName.FORGOTTEN && (
        <AccountForgottenPassword initialEmail={modal.email} />
      )}
      {modal.name === ModalName.RESET && (
        <AccountNewPassword
          email={modal.email}
          token={modal.token}
          onComplete={clearTokensAndSwitchToLogin}
        />
      )}
    </Modal>
  );
}

export default AccountModal;
