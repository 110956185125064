import { styled } from 'stitches.config';
import { ButtonBase } from './BaseButtonStyle';
import { timings, animation } from 'Theme/Settings/animation';
import { CSS } from '@stitches/react';

type PropType = {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  css?: CSS;
};

function TabButton({ onClick, children, active, css }: PropType) {
  return (
    <StyledTabButton onClick={onClick} active={active} css={css}>
      {children}
    </StyledTabButton>
  );
}

const StyledTabButton = styled('button', {
  ...ButtonBase,
  color: '$tabButtonText',
  backgroundColor: '$tabButtonBackground',
  border: 'none',
  flexGrow: 1,
  flexBasis: '33.33%',
  py: 3.5,
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  '&:hover': {
    backgroundColor: '$tabButtonHoverBackground',
  },
  fs: 6,
  '@mediaMinLarge': {
    fs: 8,
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$tabButtonActiveBackground',
        color: '$tabButtonActiveText',
        pointerEvents: 'none',
        '&:hover': {
          backgroundColor: '$tabButtonActiveBackground',
        },
      },
    },
  },
});

export default TabButton;
